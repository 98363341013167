<template>
  <metainfo />
  <HomePage />
</template>
<script>
import HomePage from "@/components/HomePage.vue";
import { useMeta } from "vue-meta";
// import { useMeta } from 'vue-meta' // import useMeta
export default {
  // https://yemsu.github.io/vue-meta-with-vue3/
  setup() {
    useMeta({
      title: "퍼플오션",
      meta: [
        { vmid: "description", name: "description", content: "퍼플오션 홈페이지" },
        {
          vmid: "keywords",
          name: "keywords",
          content: "Homepage, development, Web design, App Design",
        },
        { vmid: "og:url", name: "og:url", content: "https://new.purpleo.co.kr" },
        { vmid: "og:title", name: "og:title", content: "퍼플오션" },
        { vmid: "og:type", name: "og:type", content: "website" },
        {
          vmid: "og:image",
          name: "og:image",
          // content: "https://purpleo.co.kr/images/common/logo.png",
          content: "@/assets/images/common/logo.png",
        },
        { vmid: "og:description", name: "og:description", content: "퍼플오션 홈페이지" },
      ],
    });
  },
  components: {
    HomePage,
  },
};
</script>
<style></style>
