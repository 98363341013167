<template>
  <div ref="wrapper" id="wrapper" class="wrapper" @load="onLoadScroll" @scroll="onLoadScroll">
    <!-- 상단 헤더 { -->
    <header ref="header" id="header" class="header" :class="headerClass">
      <div class="inner-row">
        <div class="hd-top">
          <h1 class="logo">퍼플오션</h1>
        </div>
        <nav class="hd-nav">
          <a
            class="nav-item active"
            href="#none"
            title="article-work"
            @click="onItemClick('#article-work')"
            ><span>work</span></a
          >
          <a
            class="nav-item"
            href="#none"
            title="article-overview"
            @click="onItemClick('#article-overview')"
            ><span>overview</span></a
          >
          <a
            class="nav-item"
            href="#none"
            title="article-contact"
            @click="onItemClick('#article-contact')"
            ><span>contact</span></a
          >
        </nav>
      </div>
    </header>
    <!-- } 상단 헤더 -->
    <!-- 컨테이너 { -->
    <section class="container">
      <!-- 포트폴리오 -->
      <article id="article-work" class="target article-work">
        <div class="work-container inner-row">
          <a class="work-item" href="#" title="PETRO-R" data-aos="zoom-in" data-aos-offset="200">
            <img src="@/assets/images/portfolio/pf_03.jpg" />
          </a>
          <a
            class="work-item"
            href="#"
            title="collabo lounge"
            data-aos="zoom-in"
            data-aos-offset="200"
          >
            <img src="@/assets/images/portfolio/pf_02.jpg" />
          </a>
          <a
            class="work-item"
            href="#"
            title="대학드림 sos 코칭"
            data-aos="zoom-in"
            data-aos-offset="200"
          >
            <img src="@/assets/images/portfolio/pf_01.jpg" />
          </a>
        </div>
      </article>
      <!-- 개요 -->
      <article id="article-overview" class="target article-overview">
        <div class="overview-container inner-row">
          <div class="overview-text">
            <h2 data-aos="zoom-in" data-aos-delay="100" data-aos-offset="200">
              <strong>PURPLE</strong>OCEAN
            </h2>
            <p data-aos="zoom-in" data-aos-delay="200" data-aos-offset="200">
              치열한 경쟁시장인 <strong>REDOCEAN</strong>에서 발상의 전환을 통한 새로운 가치
              <strong>PURPLEOCEAN</strong>을 추구합니다.
            </p>
          </div>
          <ul class="overview-feature">
            <li class="feature-item" data-aos="zoom-in" data-aos-delay="400">
              <h3>UX / UI design</h3>
              <p>고객과의 충분한 논의를 통해<br />최상의 결과물을 제공합니다.</p>
            </li>
            <li class="feature-item" data-aos="zoom-in" data-aos-delay="400">
              <h3>UX / UI design</h3>
              <p>고객과의 충분한 논의를 통해<br />최상의 결과물을 제공합니다.</p>
            </li>
            <li class="feature-item" data-aos="zoom-in" data-aos-delay="400">
              <h3>UX / UI design</h3>
              <p>고객과의 충분한 논의를 통해<br />최상의 결과물을 제공합니다.</p>
            </li>
          </ul>
        </div>
        <iframe
          class="yt-player pc-only"
          src="https://www.youtube.com/embed/0HOLqSTSP_0?mute=1&autoplay=1&autohide=1&modestbranding=0&rel=0&loop=1&playlist=0HOLqSTSP_0&showinfo=0&controls=0&disablekb=1&enablejsapi=1&iv_load_policy=3"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen="1"
          width="100%"
          height="2000"
        >
        </iframe>
      </article>
      <!-- 컨텍트 폼 -->
      <article
        id="article-contact"
        class="target article-contact"
        data-aos="fade-up"
        data-aos-offset="200"
      >
        <div class="contact-container inner-row">
          <div class="contact-box-intro">
            <div class="intro-logo">
              <img src="@/assets/images/common/logo_trim.png" />
            </div>
            <div class="intro-text">
              <p class="name">퍼플오션(PURPLEOCEAN)</p>
              <p class="desc">저희의 작업에 관심이 있으신가요?<br />언제든지 문의 주세요😎</p>
              <a href="mailto:sooj@purpleo.co.kr" class="email"
                ><i class="material-icons">mail</i>sooj@purpleo.co.kr</a
              >
            </div>
          </div>
          <div class="contact-box-form">
            <div class="form-wrap">
              <div class="form-item">
                <label for="name">이름</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="이름을 입력하세요"
                  v-model="name"
                />
              </div>
              <div class="form-item">
                <label for="email">이메일</label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="연락받으실 메일주소를 입력하세요"
                  v-model="email"
                />
              </div>
              <div class="form-item">
                <label for="content">내용</label>
                <textarea
                  id="content"
                  name="content"
                  rows="4"
                  placeholder="문의 내용을 입력하세요"
                  v-model="mailTxt"
                ></textarea>
              </div>
              <div class="form-btn">
                <button type="button" @click="sendMail">보내기</button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
    <!-- } 컨테이너 -->
    <!-- 하단 푸터 { -->
    <footer class="footer">
      <div class="inner-row">
        <p class="site-info">
          <strong>퍼플오션(PURPLEOCEAN)</strong> | 제주특별자치도 제주시 고산동산서길 11-2, 209호 |
          사업자등록번호 551-50-00537 | 대표 정수정
        </p>
        <p class="copyrights">© 2022 PURPLEOCEAN. All rights reserved.</p>
      </div>
    </footer>
    <!-- } 하단 푸터 -->
  </div>
  <!-- SCRIPT -->
  <!-- <button @click="sendMail">메일 전송</button> -->
</template>
<script>
import { useMeta } from "vue-meta";
import axios from "axios";
export default {
  name: "HomePage",
  props: {
    msg: String,
  },
  data() {
    return {
      headerClass: "",
      name: "",
      email: "",
      mailTxt: "",
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.onLoadScroll);
    window.addEventListener("load", this.onLoadScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.onLoadScroll);
    window.removeEventListener("load", this.onLoadScroll);
  },
  setup() {
    useMeta({
      title: "퍼플오션",
      meta: [
        { vmid: "description", name: "description", content: "퍼플오션 홈페이지" },
        {
          vmid: "keywords",
          name: "keywords",
          content: "Homepage, development, Web design, App Design",
        },
        { vmid: "og:url", name: "og:url", content: "https://new.purpleo.co.kr" },
        { vmid: "og:title", name: "og:title", content: "퍼플오션" },
        { vmid: "og:type", name: "og:type", content: "website" },
        {
          vmid: "og:image",
          name: "og:image",
          // content: "https://purpleo.co.kr/images/common/logo.png",
          content: "@/assets/images/common/logo.png",
        },
        { vmid: "og:description", name: "og:description", content: "퍼플오션 홈페이지" },
      ],
    });
  },
  computed: {},
  methods: {
    onLoadScroll() {
      let scrollTop = 0;
      if (typeof pageYOffset != "undefined") {
        scrollTop = pageYOffset;
      } else {
        var B = document.body; //IE 'quirks'
        var D = document.documentElement; //IE with doctype
        D = D.clientHeight ? D : B;
        scrollTop = D.scrollTop;
      }
      if (scrollTop > 100) {
        this.headerClass = "fixed";
      } else {
        this.headerClass = "";
      }
      document.querySelectorAll(".target").forEach((e) => {
        const item = e;
        const itemOffsetTop = item.offsetTop;
        //console.log(`${scrollTop} :  ${itemOffsetTop}`);
        if (scrollTop >= itemOffsetTop - 200) {
          const id = item.getAttribute("id");
          document
            .querySelectorAll(".nav-item")
            .forEach((element) => element.classList.remove("active"));
          document.querySelector("[title='" + id + "']").classList.add("active");
        }
      });
    },
    onItemClick(sel) {
      const e = document.querySelector(sel);
      // console.log(`sel : ${sel}, ${e.offsetTop}`);
      window.scrollTo({ top: e.offsetTop - 70, left: 0, behavior: "smooth" });
    },
    sendMail() {
      this.name = this.name.trim();
      this.email = this.email.trim();
      this.mailTxt = this.mailTxt.trim();
      const regExp =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      if (this.name === "") {
        alert("이름을 입력해주세요.");
        return;
      }
      if (this.email === "") {
        alert("이메일 주소를 입력해주세요.");
        return;
      }
      if (regExp.test(this.email) !== true) {
        alert("이메일 형식으로 입력해주세요.");
        return;
      }
      if (this.mailTxt === "") {
        alert("내용을 입력해주세요.");
        return;
      }
       const url = "https://adm.purpleo.co.kr/api/send/mail";
      //const url = "http://localhost:8000/api/send/mail";
      const data = { name: this.name, email: this.email, text: this.mailTxt };

      const vm = this;
      axios
        .post(url, data)
        .then(function (res) {
          console.log(res);
          console.log(`data : ${res.data.RESULT}`);
          if (res.data.RESULT === "OK") {
            alert("메일 전송이 완료되었습니다.");
            vm.name = "";
            vm.email = "";
            vm.mailTxt = "";
          } else {
            alert("메일 전송에 실패하였습니다.");
          }
        })
        .catch(function (error) {
          console.log(error);
          //alert(JSON.stringify(error));
          alert("메일 전송에 실패하였습니다..");
        });
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixin.scss";
@import "@/assets/styles/_fonts.scss";
@import "@/assets/styles/_default.scss";
@import "@/assets/styles/style.scss";
</style>
<style scoped>
@import "https://unpkg.com/aos@2.3.1/dist/aos.css";
</style>
